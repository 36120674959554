import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTelegramPlane, faSkype, faBehanceSquare, faInstagram } from '@fortawesome/free-brands-svg-icons';
import Reveal from 'components/reveal';
import Color from 'components/colors';

import './contacts.sass';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Contacts() {
  return (
    <section id="contacts" className="contacts">
      <div className="container">
        <h2 className="contacts__title" data-title="Contacts">
          Контакты
        </h2>

        <div className="contacts__row">
          <h4 className="contacts__subtitle">
            <Reveal color={Color.c1}>Найдите меня в:</Reveal>
          </h4>

          <ul className="contacts__list">
            <li className="contacts__item">
              <a
                href="https://www.behance.net/9c14f83d"
                className="contacts__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faBehanceSquare} />
              </a>
            </li>
            <li className="contacts__item">
              <a
                href="https://www.instagram.com/yalkapsoiunov/"
                className="contacts__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} />
              </a>
            </li>
            <li className="contacts__item">
              <a
                href="tg://resolve?domain=yalkapp"
                className="contacts__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faTelegramPlane} />
              </a>
            </li>
            <li className="contacts__item">
              <a href="mailto:yalkapp@mail.ru" className="contacts__link" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </li>
            <li className="contacts__item">
              <a
                href="skype:live:.cid.11a449ae7c972add?chat"
                className="contacts__link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faSkype} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Contacts;
