import React from 'react';
import yearCalc from '../../../utils/yearCalc';

import './about.sass';

function About(props) {
  return (
    <section id="about" className="about">
      <div className="container">
        <h2 className="about__title" data-title="Обо мне">
          Обо мне
        </h2>

        <div className="about__content">
          <p>
            Я окончил Ашхабадскую строительную среднюю профессиональную школу Министерства строительства и архитектуры
            Туркменистана по специальности Архитектор.
          </p>
          <p>После обучения, начал изучить 3D Max и визуализация интерьера и экстерьера, также делать свои модели.</p>

          <h4>
            <span role="img" aria-label="">
              Мои навыки
            </span>{' '}
            💪
          </h4>
          <ul>
            <li>
              <strong>{yearCalc(2020)} года</strong> коммерческого опыта
            </li>
            <li>Отлично знаю AutoCAD, учил в спецшколе и после, и делал на нем не один чертеж ))</li>
            <li>
              Отлично знаю Adobe Photoshop, так как, после средней школы, работал фотографом и монтажером в фотостудии,
              есть большой опыт работы в этой сфере. И этот опыт, очень помогает мне делать постобработку рендеров и
              доведением до фотореалистичности.
            </li>
            <li>И не меньше, знаю 3D Max, рендереры V-Ray и Corona.</li>
            <li>3D моделирование мебели, техники и аксессуаров (hi-poly и low-ploy).</li>
            <li>Опыт работы со сложными материалами, с освещением и HDRI картами.</li>
            <li>Люблю заморочиться деталями для повышение фотореалистичности.</li>
          </ul>

          <h4>
            Почему вам нужно работать со мной? <br /> Я гарантирую:{' '}
            <span role="img" aria-label="">
              😎
            </span>
          </h4>
          <ul>
            <li>Своевременное выполнение своих задач.</li>
            <li>Внимание к деталям!</li>
            <li>Перфекционизм в пределах разумного (достичь идеала только, если это в приоритете).</li>
            <li>Я амбициозен и люблю новые вызовы и челленджи :)</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default About;
